<template>

  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="400px"
      @click:outside="close"
    >
      <v-card class="rounded-lg">
        <v-card-title class="justify-center mt-5">
          <v-img
            id="profile-img"
            src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
            class="profile-img-card"
          />
        </v-card-title>
        <v-card-text>
          <v-container>
            <div v-if="getErrors > 0">
              <v-row v-for="(err, index) in regErrors" :key="index">
                <v-alert dense outlined type="error" :width="900">
                  {{ err }}
                </v-alert>
              </v-row>
            </div>
            <v-form ref="validateRegistration">

              <v-row class="mb-0 mt-0 py-0">
<!--                <v-col class="pa-0" cols="12">-->
<!--                  <v-text-field-->
<!--                    dense-->
<!--                    filled-->
<!--                    rounded-->
<!--                    :label="firstName"-->
<!--                    v-model="user.firstName"-->
<!--                  ></v-text-field>-->
<!--                </v-col>-->
<!--                <v-col class="pa-0" cols="12">-->
<!--                  <v-text-field-->
<!--                    dense-->
<!--                    filled-->
<!--                    rounded-->
<!--                    :label="lastName"-->
<!--                    v-model="user.lastName"-->
<!--                  ></v-text-field>-->
<!--                </v-col>-->
                <v-col class="pa-0" cols="12">
                  <v-text-field
                    dense
                    filled
                    :label="username"
                    v-model="user.username"
                    :rules="rulesUsername"
                    required
                  ></v-text-field>
                </v-col>
                <v-col class="pa-0" cols="12">
                  <v-text-field
                    dense
                    filled
                    :label="password"
                    type="password"
                    v-model="user.password"
                    :rules="rulesPassword"
                    required
                  ></v-text-field>
                </v-col>
                <v-col class="pa-0" cols="12">
                  <v-text-field
                    dense
                    filled
                    :label="rePassword"
                    type="password"
                    v-model="user.rePassword"
                    :rules="rulesRePassword"
                    required
                  ></v-text-field>
                </v-col>
                <v-col class="pa-0" cols="12">
                  <v-text-field
                    dense
                    filled
                    :label="email"
                    v-model="user.email"
                    :rules="rulesEmail"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="pa-3 text-capitalize"
            rounded
            large
            @click="close"
          >
            {{$t('register.cancelBtn')}}
          </v-btn>
          <v-btn
            class="pa-3 text-capitalize"
            rounded
            large
            @click="save"
          >
            {{$t('register.registrationBtn')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

</template>

<script>

import User from '@/models/user';

export default {
  name: 'Register',
  components: {},
  data() {
    return {
      user: new User('', '', '', '', '', ''),
      dialog: false,
      regErrors: [],
      rulesUsername: [v => v !== null || this.$t('requiredField')],
      rulesPassword: [v => v !== null || this.$t('requiredField')],
      rulesRePassword: [v => v !== null || this.$t('requiredField')],
      rulesEmail: [v => v !== null || this.$t('requiredField')]
    };
  },
  computed: {
    firstName() {
      return this.$t('register.firstName');
    },
    lastName() {
      return this.$t('register.lastName');
    },
    username() {
      return this.$t('register.username');
    },
    password() {
      return this.$t('register.password');
    },
    rePassword() {
      return this.$t('register.rePassword');
    },
    email() {
      return this.$t('register.email');
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getErrors() {
      return this.regErrors.length;
    },
  },
  mounted() {
  },
  methods: {
    openRegister() {
      this.dialog = true;
    },
    close() {
      this.resetData();
    },
    resetData() {
      this.regErrors = [];
      this.dialog = false;
      this.$refs.validateRegistration.reset();
    },
    save() {
      if (!this.$refs.validateRegistration.validate()) return;
      this.$store.dispatch('auth/register', this.user).then(
        data => {
          if (data.errors && data.errors.length > 0) {
            this.regErrors = data.errors;
            this.dialog = true;
          } else {
            this.$emit('register-success', true)
            this.resetData();
          }
        },
        error => {
          this.regErrors.push(error.response?.data?.message);
          console.log('error: ', error.response.data.message);
        }
      )
    }
  }
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
</style>
