import {createRouter, createWebHistory} from 'vue-router'
import Index from '@/components/index/Index.vue';
import Login from '@/components/Login.vue';
import Register from '@/components/Register.vue';

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/register',
    component: Register,
  },
  {
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../components/about/AboutView.vue')
  },

]

const router = createRouter({
  mode: 'hash',
  history: createWebHistory(),
  routes
})

export default router
