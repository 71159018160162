<template>
  <div>
    <v-card :flat="true" :tile="true" color="grey-lighten-3">
      <v-app-bar
        color="teal-darken-4"
        image="https://picsum.photos/1920/1080?random"
      >
        <template v-slot:image>
          <v-img
            gradient="to top right, rgba(19,84,122,.8), rgba(128,208,199,.8)"
          ></v-img>
        </template>
        <v-app-bar-nav-icon x-large color="gray" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
<!--        <v-spacer/>-->
        <div class="tile-style" @click="clickTitle">
          <h1>{{ $t('navHeader.title') }}</h1>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          class="text-none goods-text"
          color=""
          :text="$t('navHeader.contact')"
          variant="plain"
          @click="showContact = !showContact"
        />
        <v-img
          class="mx-2 img-lang rounded-circle"
          max-width="26"
          max-height="26"
          contain
          :src="require('@/assets/flags/bg-flag1.png')"
          @click="changeLanguage('bg')"
          @focus="true"
        />
        <v-img
          class="mx-2 img-lang rounded-circle"
          max-width="26"
          max-height="26"
          contain
          :src="require('../../assets/flags/en-flag1.png')"
          @click="changeLanguage('en')"
          @focus="true"
        />
        <v-icon
          size="x-large"
          class="ma-8"
          color="gray"
          @click="authDrawer = !authDrawer"
        >
          mdi-account-circle
        </v-icon>
      </v-app-bar>
      <v-main></v-main>
    </v-card>

    <v-navigation-drawer
      mobileBreakpoint="750"
      theme="background"
      class="base-style drawer-bord"
      :permanent="true"
      :floating="true"
      :absolute="true"
      :width="215"
      v-model="drawer"
      location="left"
    >
      <v-list dense >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :link="true"
          @click="executeMethod(item.value)"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :start="true"
                v-bind="attrs"
                v-on:click="on">{{ item.icon }}
              </v-icon>
              <a class="drawer-text">{{ item.title }}</a>

            </template>
            <span>{{ item.title }}</span>
          </v-tooltip>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer
      mobileBreakpoint="750"
      theme="background"
      class="base-style drawer-bord"
      :absolute="true"
      :permanent="true"
      :floating="true"
      :width="185"
      v-model="authDrawer"
      location="right"
    >
      <template v-slot:prepend>
        <v-list-item
          v-show="currentUser"
          lines="two"
          prepend-icon="mdi-account-circle"
          :title="$t('navHeader.subTitle')"
          :subtitle="currentUser && currentUser.username"
        ></v-list-item>
      </template>

      <v-list color="transparent">
        <v-list-item
          v-show="!currentUser"
          prepend-icon="mdi-login"
          :title="$t('navHeader.login')"
          @click="login"></v-list-item>
        <v-list-item
          v-show="!currentUser"
          prepend-icon="mdi-account-plus-outline"
          :title="$t('navHeader.register')"
          @click="openRegister"></v-list-item>
        <v-list-item
          v-show="currentUser"
          prepend-icon="mdi-logout"
          :title="$t('navHeader.logout')"
          @click="logOut"></v-list-item>
      </v-list>

      <!--      <template v-slot:append>-->
      <!--        <div class="pa-2">-->
      <!--          <v-btn-->
      <!--            v-show="currentUser"-->
      <!--            class="text-none mb-1 base-style"-->
      <!--            block-->
      <!--            @click="logOut">-->
      <!--            <v-icon>-->
      <!--              mdi-logout-->
      <!--            </v-icon>-->
      <!--            Logout-->
      <!--          </v-btn>-->
      <!--        </div>-->
      <!--      </template>-->
    </v-navigation-drawer>
    <Register ref="register" @register-success="registerSuccess"/>
    <Login ref="login" @openRegister="closeLogin"/>
  </div>
</template>

<script>


import Login from '@/components/Login.vue';
import Register from '@/components/Register.vue';

export default {
  name: 'NavHeader',
  props: {},
  components: {Register, Login},
  data: () => ({
    drawer: false,
    authDrawer: false,
    group: null,
    showContact: false,
    openCSV: false,
    openExample: false,
    fullVersion: false,
  }),
  watch: {
    authDrawer(val) {
      if (val) {
        this.drawer = false;
      }
    },
    drawer(val) {
      if (val) {
        this.authDrawer = false;
        this.showContact = false;
      }
    },
    showContact(val) {
      this.$emit('show-contact', val);
    }
  },
  computed: {
    items() {
      return [
        {title: this.$t('navHeader.readItemTitle'), icon: 'mdi-file-delimited-outline', value: 'readCSV'},
        {title: this.$t('navHeader.comingItemTitle1'), icon: 'mdi-magnify', value: 'openExample'},
        // {title: this.$t('navHeader.comingItemTitle2'), icon: 'mdi-magnify', value: 'fullVersion'},*
        {title: '', icon: 'mdi-magnify', value: ''},
      ];
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    clickTitle() {
      this.$router.push('/');
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
    },
    executeMethod(action) {
      switch (action) {
        case 'readCSV' :

          this.$emit('openWorkComponent', 'openCSV');
          break;
        case 'openExample' :
          this.$emit('openWorkComponent', 'openExample');
          break;
        case 'fullVersion' :
          // this.$emit('openWorkComponent', 'fullVersion');
          break;
      }
      this.drawer = !this.drawer
    },
    logOut() {
      this.authDrawer = !this.authDrawer;
      this.$store.dispatch('auth/logout');
      this.$router.push('/');
    },
    login() {
      this.authDrawer = !this.authDrawer;
      this.$refs.login.openLogin();
    },
    openRegister() {
      this.authDrawer = !this.authDrawer;
      this.$refs.register.openRegister();
    },
    registerSuccess(val) {
      if (val) {
        this.$refs.login.openLogin();
      }
    },
    closeLogin() {
      this.$refs.login.close();
      this.openRegister();
    },
  },
}
</script>


<style scoped>
.v-navigation-drawer-transparent {
  background: transparent;
}

.img-lang {
  cursor: pointer;
}

.tile-style {
  cursor: pointer;
  font-family: 'Segoe Script', serif;
  color: #82acad;
}

.rounded-circle {
  border-radius: 50%;
}
.drawer-bord {
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);
}
</style>
