export const en = {
  requiredField: 'Required field',
  requiredBtn: 'Required button',
  indexTitle: 'Customs DOCUment',
  username: 'username',
  password: 'password',
  login: {
    login: 'Login',
    registration: 'Registration',
    submit: 'Submit',
    password: {
      doesntMatch: 'Password does not match'
    }
  },
  register: {
    cancelBtn: 'Cancel',
    registrationBtn: 'Registration',
    firstName: 'firstName',
    lastName: 'lastName',
    username: 'username',
    password: 'password',
    rePassword: 'rePassword',
    email: 'email',
  },
  navHeader: {
    title: 'DOCUment < ...  \\>',
    login: 'Login',
    register: 'Register',
    logout: 'Logout',
    subTitle: 'Logged in: ',
    readItemTitle: 'Read  *excel',
    comingItemTitle1: 'Examples',
    comingItemTitle2: 'Coming soon . . ',
    contact: 'Contact',
  },
  readCsv: {
    title: 'Read EXCEL File',
    eur1Btn: 'EUR1',
    withoutEur1Btn: 'Without EUR1',
    explicitCountry: 'Explicit Country Presentation:',
    chooseCurrency: 'Select currency',
    exportLabel: 'Export',
    importLabel: 'Import',
    uploadCsvLabel: 'Upload EXCEL file',
    uploadCsvPlaceHolder: 'Select your files',
    uploadBtn: 'Upload EXCEL',
    exportXmlBtn: 'Export to XML',
    exportExcelBtn: 'Export to Excel',
    tableViewBtn: 'Table View',
    cardsViewBtn: 'Cards View',
    printBtn: 'Print',
    fullVerBtn: 'Full Version',
  },
  resultTable: {
    headerTitle: 'Items...',
    searchLabel: 'Search',
    headers: {
      index: 'No',
      descriptions: 'Descriptions',
      taricCode: 'TARIC code',
      gross: 'Gross weight',
      net: 'Net weight',
      eur1: 'EUR1',
      amount: 'Amount',
      boxes: 'Boxes',
      genOrigin: 'General Origin',
      origin: 'Origin',
      invoice: 'Invoice No ',
    },
  },
  resultViewCsv: {
    headerTitle: 'Items...',
    taricCode: 'TARIC code:',
    descriptions: 'Descriptions:',
    boxes: 'Boxes:',
    genOrigin: 'General origin of goods:',
    origin: 'Origin of goods:',
    gross: 'Gross weight:',
    net: 'Net weight:',
    amount: 'Amount:',
    eur1: 'EUR1:',
    invoice: 'Invoice No :',
  },
  example: {
    title: 'Example for EXCEL',
    ruleColumnName: '* The column name must be one of the items listed in the example.',
    helpCSVcoma: '* You must be sure that there is no \' , \' (comma) anywhere in the CSV file.',
    helpOrderColumn: '* It does not matter how the columns are arranged',
    helpLowerUpperCase: '* It does not matter if the column names are in upper or lowercase',
  },
  fullVersion: {
    import: 'Import',
    export: 'Export',
    toBaseModule: 'To Module Broadcasting',
    asOfDate: 'As Of Date',
    fullEditing: 'Full Edit',
    custOfficeCode: 'CO on submission',
    declarationType: 'Declaration Type',
    addDeclarationType: 'Additional Declaration Code',
  }
}
