import { createI18n } from 'vue-i18n/dist/vue-i18n.cjs.js'
import { en } from './i18n/en'
import { bg } from './i18n/bg'

const messages = {
  bg: bg,
  en: en
}

const i18n = createI18n({
  locale: 'bg',
  fallbackLocale: 'en',
  messages
})

export default i18n
