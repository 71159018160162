export const bg = {
  requiredField: 'Задължително поле',
  requiredBtn: 'Задължителен бутон',
  indexTitle: 'Customs DOCUment',
  username: 'username',
  password: 'парола',
  login: {
    login: 'Вход',
    registration: 'Регистрация',
    submit: 'Изпращане',
    password: {
      doesntMatch: 'Паролата не съответства'
    }
  },
  register: {
    cancelBtn: 'Отказ',
    registrationBtn: 'Регистрация',
    firstName: 'Име',
    lastName: 'Фамилно име',
    username: 'username',
    password: 'Парола',
    rePassword: 'Повтори паролата',
    email: 'Имейл',
  },
  navHeader: {
    title: 'DOCUment < ...  \\>',
    login: 'Вход',
    register: 'Регистрация',
    logout: 'Изход',
    subTitle: 'Вход, като: ',
    readItemTitle: 'Прочети от  *excel',
    comingItemTitle1: 'Примери',
    comingItemTitle2: 'Пълна версия',
    contact: 'Контакт',
  },
  readCsv: {
    title: 'Четене на EXCEL Файл',
    eur1Btn: 'EUR1',
    withoutEur1Btn: 'Без EUR1',
    explicitCountry: 'Изрично избиране на страна:',
    chooseCurrency: 'Изберете валута на фактурата',
    exportLabel: 'Износ',
    importLabel: 'Внос',
    uploadCsvLabel: 'Изтегляне на EXCEL файл',
    uploadCsvPlaceHolder: 'Изберете файл',
    uploadBtn: 'Изтегли EXCEL',
    exportXmlBtn: 'Експорт в XML формат',
    exportExcelBtn: 'Експорт в Excel формат',
    tableViewBtn: 'Табличен изглед',
    cardsViewBtn: 'Картов изглед',
    printBtn: 'Принт',
    fullVerBtn: 'Пълна Версия',
  },
  resultTable: {
    headerTitle: 'Стоки...',
    searchLabel: 'Търсене',
    headers: {
      index: '№',
      descriptions: 'Описание',
      taricCode: 'Тарифен код',
      gross: 'Бруто кг.',
      net: 'Нето кг.',
      eur1: 'EUR1',
      amount: 'Стойност',
      boxes: 'Колети',
      genOrigin: 'Главен произход',
      origin: 'Произход',
      invoice: 'Фактура № ',
    },
  },
  resultViewCsv: {
    headerTitle: 'Стоки...',
    taricCode: 'Тарифен код:',
    descriptions: 'Описание:',
    boxes: 'Колети:',
    genOrigin: 'Главен произход:',
    origin: 'Произход:',
    gross: 'Бруто кг. :',
    net: 'Нето кг. :',
    amount: 'Стойност:',
    eur1: 'EUR1:',
    invoice: 'Фактура № :',
  },
  example: {
    title: 'Пример за EXCEL',
    ruleColumnName: '* Името на колоната трябва да съдържа едно от изброените в примера',
    helpCSVcoma: '* Трябва да сте сигурни, че няма \' , \' (запетайка) където и да е във CSV файла',
    helpOrderColumn: '* Без значение как се подредени колоните',
    helpLowerUpperCase: '* Без значение имената на колоните дали са с големи или малки букви',
  },
  fullVersion: {
    import: 'Внос',
    export: 'Износ',
    toBaseModule: 'Към Модул Излъчване',
    asOfDate: 'Дата',
    fullEditing: 'Редедактиране на всички данни',
    custOfficeCode: 'MY на подаване',
    declarationType: 'Вид декларация',
    addDeclarationType: 'Допълнителен код декларацията',
  }
}
