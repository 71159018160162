import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import vuetify from 'vuetify'
import { loadFonts } from './plugins/webfontloader'
import i18n from './i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './styles/base/customs-global.css'
import papa from 'papaparse';
import ExcelJS from 'exceljs/dist/exceljs.min.js';
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    iconfont: 'mdi',
  },
})

loadFonts()

createApp(App)
  .use(router)
  .use(store)
  .use(i18n)
  .use(vuetify)
  .use(papa)
  .use(ExcelJS)
  .use(VueAxios, axios)
  .mount('#app');
