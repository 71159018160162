<template>
  <!--  <v-row>-->
  <!--    <v-col cols="12"-->
  <!--           sm="12"-->
  <!--           md="4">-->
  <v-menu
    ref="open"
    v-model="open"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ props }">
      <v-text-field
        variant="underlined"
        v-model="getDate"
        :label="$t('fullVersion.asOfDate')"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="props"
      />
    </template>
    <v-date-picker
      v-model="date"
      no-title
      scrollable
      @input="open = false"
    >
      <!--      <v-spacer></v-spacer>-->
      <!--      <v-btn-->
      <!--        text-->
      <!--        color="primary"-->
      <!--        @click="menu = false"-->
      <!--      >-->
      <!--        Cancel-->
      <!--      </v-btn>-->
      <!--      <v-btn-->
      <!--        text-->
      <!--        color="primary"-->
      <!--        v-on:click="saveDate"-->
      <!--      >-->
      <!--        OK-->
      <!--      </v-btn>-->
    </v-date-picker>
  </v-menu>
  <!--    </v-col>-->
  <!--  </v-row>-->
</template>

<script>
export default {
  name: 'Calendar',
  data() {
    return {
      open: false,
      date: null,
      menu: false,
    };
  },
  computed: {
    getDate() {
      // (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      let inputDate = '';
      if (!this.date) {
        inputDate = new Date();
        // const ddDate = parseInt(this.$vuetify.date.toISO(fDate).split('-')[2], 10);
        // return null;
      } else {
        inputDate = this.date;
      }
      // const inputDate = new Date(asOfDate);
      const day = String(inputDate.getDate()).padStart(2, '0');
      const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Месеците в JavaScript започват от 0, затова добавяме 1
      const year = inputDate.getFullYear();
      const formattedDate = `${day}/${month}/${year}`;
      this.$emit('asOfDate', formattedDate)
      // this.$emit('asOfDate', inputDate)
      return formattedDate;
    },
  },
  methods: {
    saveDate() {
      console.log('this.date ', this.date)
      this.menu = false;
    },
  },

};
</script>

<style scoped>

</style>
