<template>
  <v-app class="base-style">
    <NavHeader
      @show-contact="showContact"
      @open-work-component="openWorkComponent"/>
    <Contact
      v-show="isContact"/>
    <!--    <h1 class="text-center mt-16" v-show="!openCSV">{{$t('indexTitle')}}</h1>-->
    <read-csv
      ref="resetReadCsv"
      v-show="currentUser && openCSV"
      :fullVerData="getFullVerData"
      :currencyList="currencyList"
      :eurCountryList="eurCountryList"
      @openFullVersion="openFullVersion"
    />
    <example
      v-show="openExample"
      @open-base-module="openBaseModule"
    />
    <full-version
      v-if="false"
      ref="resetAdditionalData"
      v-show="currentUser && fullVersion"
      :mode="getMode"
      :currencyList="currencyList"
      :eurCountryList="eurCountryList"
      @open-base-module="openBaseModule"
    />
  </v-app>
</template>

<script>

import NavHeader from '@/components/header/NavHeader.vue';
import ReadCsv from '@/components/csv/ReadCsv.vue';
import Contact from '@/components/contact/Contact.vue';
import Example from '@/components/csv/Example.vue';
import FullVersion from '@/components/fullVersion/FullVersion.vue';
import Calendar from '@/components/calendar/Calendar.vue';
import FileService from '@/services/file-service';


export default {
  name: 'Index',
  components: { FullVersion, Example, Contact, ReadCsv, NavHeader},
  data() {
    return {
      drawer: false,
      openCSV: false,
      isContact: false,
      openExample: false,
      fullVersion: false,
      mode: null,
      fullVerData: null,
      // resetData: true,
      currencyList: [],
      eurCountryList: [],
    }
  },
  mounted() {
    this.fetchInitialData();
  },
  computed: {
    getResetData() {
      return this.resetData;
    },
    getMode() {
      return this.mode;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    getFullVerData() {
      return this.fullVerData;
    },
  },
  methods: {
    openBaseModule(val) {
      if (val) {
        this.fullVerData = val;
      }
      // this.resetData = false;
      this.openExample = false;
      this.fullVersion = false;
      this.openCSV = true;
    },
    openFullVersion(val) {
      // this.resetData = false;
      this.openCSV = false;
      this.openExample = false;
      this.mode = val;
      this.fullVersion = true;
    },
    showContact(val) {
      this.isContact = val;
    },
    openWorkComponent(val) {
      switch (val) {
        case 'openCSV' :
          this.openCSV = true;
          this.openExample = false;
          this.fullVersion = false;
          this.$refs.resetReadCsv.resetCsvData();
          break;
        case 'openExample' :
          this.openCSV = false;
          this.openExample = true;
          this.fullVersion = false;
          break;
        case 'fullVersion' :
          this.$refs.resetAdditionalData.resetAdditional();
          this.openCSV = false;
          this.openExample = false;
          this.fullVersion = true;
          break;
      }
    },
    fetchInitialData() {
      FileService.getInitialData()
        .then(response => {
          this.eurCountryList = [...response.data.euroCountries];
          this.currencyList = [...response.data.exchangeRates];
        })
        .catch(e => {
          this.errors.push(e.response.data.message);
          console.log(e);
        });
    },
  }
}
</script>

<style scoped>

</style>
