import axios from 'axios';
import authHeader from '@/services/auth-header';

const API_URL = 'https://customs-api.onrender.com/api/csv/';

class FileService {
  uploadFile(resultModel) {
    const formData = this.getRequestParams(resultModel);
    return axios.post(API_URL + 'upload-csv', formData, {headers: authHeader()});
  }

  getInitialData() {
    return axios.get(API_URL + 'init-helps-list', {headers: authHeader()});
  }

  getRequestParams(resultModel) {
    const formData = new FormData();
    resultModel.files.forEach(i => formData.append('file', i));
    if (resultModel.explicitEurList.length > 0) {
      resultModel.explicitEurList.forEach(i => formData.append('explicitEurList', i));
    } else {
      formData.append('explicitEurList', []);
    }
    formData.append('eur1', resultModel.eur1);

    return formData;
  }
}

export default new FileService();
