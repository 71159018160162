<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="350px"
      @click:outside="close"
    >
      <v-card class="rounded-lg">
        <v-card-title class="justify-center mt-5">
          <v-img
            id="profile-img"
            src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
            class="profile-img-card"
          />
        </v-card-title>
        <v-card-text>
          <v-container>
            <div v-if="getErrors > 0">
              <v-row v-for="(err, index) in regErrors" :key="index" class="mb-0 mt-0 py-0">
                <v-alert dense outlined type="error" :width="900">
                  {{ err }}
                </v-alert>
              </v-row>
            </div>

            <v-form ref="validateLogin">
              <v-row class="pa-0">
                <v-col class="pa-0" cols="12">
                  <v-text-field
                    dense
                    filled
                    :label="username"
                    v-model="user.username"
                    :rules="rulesUsername"
                    required
                  ></v-text-field>
                </v-col>
                <v-col class="pa-0" cols="12">
                  <v-text-field
                    dense
                    filled
                    :label="password"
                    type="password"
                    v-model="user.password"
                    :rules="rulesPassword"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="pa-3 text-capitalize"
                  rounded
                  large
                  @click="openRegister"
                >
                  {{$t('login.registration')}}
                </v-btn>
                <v-btn
                  class="pa-3 text-capitalize"
                  rounded
                  large
                  @click="login"
                >
                  {{$t('login.submit')}}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
      <v-overlay :value="overlay">
        <v-progress-circular
          :size="80"
          :width="7"
          indeterminate>
        </v-progress-circular>
      </v-overlay>
    </v-dialog>
  </v-row>
</template>

<script>
import User from '@/models/user';

export default {
  name: 'Login',
  components: {},
  data() {
    return {
      user: new User('', ''),
      dialog: false,
      regErrors: [],
      rulesUsername: [v => !!v || this.$t('requiredField')],
      rulesPassword: [v => !!v || this.$t('requiredField')],
      message: null,
      overlay: false,
    };
  },
  computed: {
    username() {
      return this.$t('username');
    },
    password() {
      return this.$t('password');
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getErrors() {
      return this.regErrors.length;
    },
  },
  created() {
  },
  methods: {
    openLogin() {
      this.dialog = true;
    },
    openRegister() {
      this.$emit('openRegister');
    },
    close() {
      this.resetData();
    },
    resetData() {
      this.regErrors = [];
      this.dialog = false;
      this.$refs.validateLogin.reset();
    },
    login() {
      if (!this.$refs.validateLogin.validate()) return;
      this.overlay = true;
      this.$store.dispatch('auth/login', this.user).then(
        () => {
          this.dialog = false;
          this.overlay = false;
          this.$emit('success', true)
        },
        error => {
          this.dialog = true;
          this.overlay = false;
          if (error.response.status === 401) {
            this.message = this.$t('login.password.doesntMatch');
            this.regErrors.push(this.message)
            this.$emit('success', false)
          } else {
            this.message =
              (error.response && error.response.data && error.response.data.message) ||
              error.response.data ||
              error.toString();
          }
        }
      );
    },
  }
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
</style>
