<template>
  <br>
  <br>
  <br>
  <br>
Reni
Reni
Reni
Reni
Reni
</template>
<script>
export default {
  name: 'Company'
}
</script>


<style scoped>

</style>
