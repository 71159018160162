<template>
  <div v-if="currentUser"
       class="text-center">
    <div v-if="getErrors > 0">
      <v-row v-for="(err, index) in errors"
             :key="index"
             class="ma-5 py-5">
        <v-alert dense
                 outlined
                 type="error">
          {{ err }}
        </v-alert>
      </v-row>
    </div>
    <v-container>
      <p class="title-csv">{{ $t('readCsv.title') }}</p>
      <v-form ref="readCsv">
        <v-card class="rounded-card">
          <div class="d-flex flex-row mt-4">
            <div class="justify-space-between">
              <v-radio-group
                class="mx-10"
                v-model="eur1"
                :inline="false"
              >
                <v-radio
                  color="grey"
                  :label="$t('readCsv.eur1Btn')"
                  :value="true"/>
                <v-radio
                  color="grey"
                  :label="$t('readCsv.withoutEur1Btn')"
                  :value="false"/>
              </v-radio-group>
            </div>
            <v-autocomplete
              class="mx-16"
              variant="outlined"
              v-model="explicitEurList"
              item-value="key"
              item-title="value"
              :multiple="true"
              :chips="true"
              :disabled="!eur1"
              :label="$t('readCsv.explicitCountry')"
              :items="eurCountryList"
            />
          </div>
          <div class="d-flex flex-row mt-4">
            <div class="justify-space-between">
              <v-radio-group
                class="mx-10"
                v-model="mode"
                :inline="true"
              >
                <v-radio
                  value="import"
                  :class="isImportActive ? 'import-rLabel-active' : 'colored-rLabel'"
                >
                  <template v-slot:label>
                    <v-label class="label-rBtn">{{ $t('readCsv.importLabel') }}</v-label>
                  </template>
                </v-radio>
                <v-radio
                  value="export"
                  :class="isExportActive ? 'export-rLabel-active' : 'colored-rLabel'"
                >
                  <template v-slot:label>
                    <v-label class="label-rBtn">{{ $t('readCsv.exportLabel') }}</v-label>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>
            <v-text-field
              class="ml-2"
              :model-value="getCurrencyValue"
              :readonly="true"
              :label="getChosenCurrency"
              variant="solo"
            />
            <v-autocomplete
              class="mx-16"
              variant="outlined"
              v-model="chosenCurrency"
              item-value="code"
              item-title="codeCurrencyText"
              :multiple="false"
              :chips="true"
              :label="$t('readCsv.chooseCurrency')"
              :items="getCurrencyList"
              :rules="[(v)=> !!v || $t('requiredField')]"
            />

          </div>
          <v-card-item>
            <div class="ma-8">
              <v-file-input
                :title="$t('readCsv.uploadCsvLabel')"
                v-model="files"
                :multiple="true"
                accept=".xlsx, .xls"
                color="deep-purple-accent-4"
                :counter="true"
                :label="$t('readCsv.uploadCsvLabel')"
                :placeholder="$t('readCsv.uploadCsvPlaceHolder')"
                prepend-icon="mdi-paperclip"
                variant="outlined"
                :rules="[(v) => v && v.length > 0 || $t('requiredField')]"
              >
                <template v-slot:selection="{ fileNames }">
                  <template v-for="(fileName, index) in fileNames"
                            :key="fileName">
                    <v-chip
                      v-if="index < 2"
                      color="deep-purple-accent-4"
                      :label="true"
                      size="small"
                      class="me-2"
                    >
                      {{ fileName }}
                    </v-chip>
                    <span
                      v-else-if="index === 2"
                      class="text-overline text-grey-darken-3 mx-2"
                    >
          +{{ files.length - 2 }} File(s)
        </span>
                  </template>
                </template>
              </v-file-input>
            </div>
          </v-card-item>
          <v-card-item>
            <div class="text-right">
              <v-btn
                class="text-none mb-1"
                :text="$t('readCsv.uploadBtn')"
                rounded="lg"
                size="x-large"
                color="#3cada3"
                image="https://picsum.photos/1920/1080?random"
                @click="uploadFile"
              />
            </div>
          </v-card-item>
        </v-card>

      </v-form>
    </v-container>
    <v-divider/>
    <div class="d-flex">
<!--      <v-btn-->
<!--        v-if="getCsvResponseList.length > 0"-->
      <v-btn
        v-if="false"
        class="text-none"
        color="teal-darken-1"
        :text="$t('readCsv.fullVerBtn')"
        variant="plain"
        @click="openFullVersion"
      />

      <div class="flex-grow-1 text-right">
        <v-btn
          class="text-none"
          color="teal-darken-1"
          :text="$t('readCsv.exportXmlBtn')"
          variant="plain"
          @click="exportToXML"
        />
        <v-btn
          class="text-none"
          color="teal-darken-1"
          :text="$t('readCsv.exportExcelBtn')"
          variant="plain"
          @click="exportToExcel"
        />
        <v-btn
          class="text-none"
          color="teal-darken-1"
          :text="showCards ? $t('readCsv.tableViewBtn') : $t('readCsv.cardsViewBtn')"
          variant="plain"
          @click="openCards"
        />
        <v-btn
          class="text-none"
          :text="$t('readCsv.printBtn')"
          color="teal-darken-1"
          variant="plain"
          @click="printTable"
        />
      </div>
    </div>
    <result-view-csv
      v-if="getCsvResponseList.length > 0 && showCards"
      :csvList="getCsvResponseList"/>
    <result-table
      v-if="getCsvResponseList.length > 0 && !showCards"
      :csv="getPrintCSV"
      :csvList="getCsvResponseList"
      @close-print-csv="closePrintCsv"
    />
    <export-xml
      v-if="getCsvResponseList.length > 0"
      :chosenCurrency="getChosenCurrency"
      :chosenRate="getCurrencyValue"
      :exportXml="exportXml"
      :mode="getMode"
      :csvList="getCsvResponseList"
      @export-xml-send="exportXml = false"
      @reset-file="resetFile"
    />
  </div>
</template>


<script>
import FileService from '@/services/file-service';
import ResultViewCsv from '@/components/csv/ResultViewScv.vue';
import ResultTable from '@/components/csv/ResultTable.vue';
import ExportXml from '@/components/csv/ExportXml.vue';
import {fullVer} from '@/mixins/fullVer.js';

export default {
  name: 'ReadCsv',
  components: {ExportXml, ResultTable, ResultViewCsv},
  mixins: [fullVer],
  props: {
    fullVerData: Object,
    currencyList: Array,
    eurCountryList: Array,
  },
  data: () => ({
    files: [],
    csvResponseList: [],
    errors: [],
    eur1: false,
    // currencyList: [],
    // eurCountryList: [],
    explicitEurList: [],
    showCards: false,
    printCSV: false,
    exportXml: false,
    mode: 'import',
    currencyValue: null,
    chosenCurrency: null,
    currencyRates: [],
  }),
  // mounted() {
  //   this.fetchInitialData();
  // },
  computed: {
    getChosenCurrency() {
      return this.chosenCurrency;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    getPrintCSV() {
      return this.printCSV;
    },
    getErrors() {
      return this.errors.length;
    },
    getCsvResponseList() {
      return this.csvResponseList;
    },
    getMode() {
      return this.mode;
    },
    getCurrencyValue() {
      if (this.currencyValue !== null) {
        return parseFloat(this.currencyValue.replace(',', '.'));
      }
      return this.currencyValue;
    },
    getCurrencyList() {
      return this.currencyList;
    },
    isImportActive() {
      return this.mode === 'import';
    },
    isExportActive() {
      return this.mode === 'export';
    },
  },
  updated() {
    this.$refs.readCsv.resetValidation();
  },
  watch: {
    fullVerData: {
      handler: function (val) {
        if (val) {
          this.mode = val.fullVerMode;
        }
      },
      deep: true,
      immediate: true
    },
    eur1(val) {
      if (!val) {
        this.explicitEurList = [];
      }
    },
    chosenCurrency(val) {
      if (val) {
        this.currencyValue = this.currencyList.find((f) => f.code === val).rate;
      }
    }
  },
  methods: {
    resetCsvData() {
      this.chosenCurrency = null;
      this.currencyValue = null;
      this.csvResponseList = [];
      if (this.files.length > 0) {
        this.files = [];
      }
    },
    openFullVersion() {
      this.$emit('openFullVersion', this.mode);
    },
    resetFile() {
      this.exportXml = false;
    },
    closePrintCsv() {
      this.printCSV = false;
    },
    exportToXML() {
      this.exportXml = true;
    },
    exportToExcel() {
      if (this.csvResponseList.length > 0) {
        this.printCSV = true;
      }
    },
    openCards() {
      this.showCards = !this.showCards;
    },
    // fetchInitialData() {
    //   FileService.getInitialData()
    //     .then(response => {
    //       this.eurCountryList = [...response.data.euroCountries];
    //       this.currencyList = [...response.data.exchangeRates];
    //     })
    //     .catch(e => {
    //       this.errors.push(e.response.data.message);
    //       console.log(e);
    //     });
    // },
    uploadFile() {
      if (!this.$refs.readCsv.validate()) {
        return;
      }
      if (this.files === null || this.files.length === 0) {
        return;
      }
      const resultModel = {
        files: this.files,
        explicitEurList: this.explicitEurList,
        eur1: this.eur1,
        mode: this.mode
      }
      FileService.uploadFile(resultModel)
        .then(response => {
          this.csvResponseList = [...response.data];
          // this.$refs.readCsv.reset();
          this.errors = [];
        })
        .catch(e => {
          this.errors.push(e.response.data);
          console.log(e)
        })
    },
    printTable() {
      window.print();
    },
  }
}
</script>

<style scoped>
.csv-custom-label {
  margin-top: -60px;
  margin-bottom: 20px;
  margin-left: 30px;
  justify-content: left;
}

.title-csv {
  color: #3cada3 !important;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bolder;
  text-align: left;
  background: transparent;
}

.rounded-card {
  border-radius: 10px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);
}
</style>
