<template>
  <v-card class="d-flex flex-column mx-1 rounded-card pt-5"
          :style="{ 'max-width': '400px', 'min-width': '400px' }">
    <!--:style="{ 'max-width': '200px', 'min-width': '200px' }"-->

    <v-combobox
      class="line-class"
      :class="getInheritanceClass('mx-5')"
      variant="underlined"
      v-model="exporterName"
      item-value="name"
      item-title="name"
      :label="'Износител'"
      :items="getCompanies"
    />


    <v-text-field
      :class="getInheritanceClass('mx-5')"
      variant="underlined"
      class="mx-5 line-class"
      :label="'Адрес'"
      v-model="exporter.address"
    />

    <v-text-field
      :class="getInheritanceClass('mx-5')"
      variant="underlined"
      class="mx-5 line-class"
      :style="{ 'max-width': '200px', 'min-width': '200px' }"
      :label="'Град'"
      v-model="exporter.city"
    />

    <v-text-field
      :class="getInheritanceClass('mx-5')"
      variant="underlined"
      class="mx-5 line-class"
      :style="{ 'max-width': '130px', 'min-width': '130px' }"
      :label="'Пощенски Код'"

      v-model="exporter.postCode"
    />

    <div class="d-flex flex-row">
      <v-text-field
        :class="getInheritanceClass()"
        variant="underlined"
        class="mx-5 line-class"
        :label="'ЕОРИ'"

        v-model="exporter.eori"
      />

      <v-combobox
        :style="{ 'max-width': '100px', 'min-width': '100px' }"
        class="line-class"
        :class="getInheritanceClass('mx-3')"
        variant="underlined"
        v-model="exporter.country"
        item-value="value"
        item-title="text"
        :label="'Държава'"
        :items="countries"
      />
    </div>


  </v-card>
</template>
<script>
import helpDataService from '@/services/help-data-service';

export default {
  name: 'Exporter',
  props: {
    isImportActive: Boolean,
  },
  data() {
    return {
      exporter: {
        eori: null,
        name: null,
        address: null,
        city: null,
        postCode: null,
        country: null,
      },
      exporterName: null,
      countries: helpDataService.countries(),
      companies: helpDataService.companies(),
    }
  },
  watch: {
    exporterName (val) {
      if (typeof val === 'object') {
        this.exporter = {
          eori: val.eori,
          name: val.name,
          address: val.address,
          city: val.city,
          postCode: val.postCode,
          country: val.country,
        }
      } else if (val && typeof val === 'string') {
        this.exporter = {
          eori: null,
          name: val,
          address: null,
          city: null,
          postCode: null,
          country: null,
        }
      }
    },

  },
  computed: {
    getCompanies() {
      return Array.from(this.companies.values());
    }
    ,
  },
  methods: {
    getInheritanceClass(addClass) {
      return this.isImportActive ? `${addClass} import-rLabel-active` : `${addClass} export-rLabel-active`;
    }
    ,
  }
}
</script>


<style scoped>
.import-rLabel-active {
  //font-weight: 420;
  color: teal !important;
}

.export-rLabel-active {
  //font-weight: 520;
  color: dodgerblue !important;
}

.line-class {
  margin: -10px;
}

</style>
