<template>
  <div class="mt-16 text-center goods-text">
    <p>phone: +359 888 55 79 79</p>
    <p>email: nikeza@gmail.com</p>
    <p>Nikolay Ivanov</p>
    <v-btn
      color="#1877F2"
      icon="mdi-facebook"
      variant="text"
      target="_blank"
      href="https://www.facebook.com/profile.php?id=61555184183230"
    />
  </div>

</template>

<script>
export default {
  name: 'Contact',
  props: {}
}
</script>

<style scoped>

</style>
