export const fullVer = {
  data: () => ({
    chosenCurrency: null,
    currencyValue: null,
  }),
  computed: {
    getChosenCurrency() {
      return this.chosenCurrency;
    },
    getCurrencyList() {
      return this.currencyList;
    },
    getCurrencyValue() {
      if (this.currencyValue !== null) {
        return parseFloat(this.currencyValue.replace(',', '.'));
      }
      return this.currencyValue;
    },
  },
  watch: {
    chosenCurrency(val) {
      if (val) {
        this.currencyValue = this.currencyList.find((f) => f.code === val).rate;
      }
    },
  },

}
