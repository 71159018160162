<template>
  <div v-if="getCsvList !== null">
    <div class="base-style text-center">
      <v-label class="bold-label csv-custom-label">{{ $t('resultTable.headerTitle') }}</v-label>
    </div>
    <v-container :fluid="true">
      <v-card>
        <v-card-title>
          Search...
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('resultTable.searchLabel')"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>

        <v-data-table
          :density="'compact'"
          :search="search"
          :headers="headers"
          :items="getCsvList">
          <template v-slot:headers="{ columns }">
            <tr>
              <template v-for="column in columns" :key="column.key">
                <td>
                  <span class="header-table">{{ column.title }}</span>
                </td>
              </template>
            </tr>
          </template>
          <template v-slot:item="{ item, index }">
            <tr>
              <td>{{ index + 1 + '.' }}</td>
              <td class="text-left">{{ item.itemDesc }}</td>
              <td>{{ item.cCode }}</td>
              <td>{{ item.gross }}</td>
              <td>{{ item.net }}</td>
              <td>{{ getEur1(item.eur1) }}</td>
              <td>{{ item.totalAmount }}</td>
              <td>{{ item.boxes }}</td>
              <td>{{ item.genOrigin }}</td>
              <td>{{ item.origin }}</td>
              <td>{{ item.invoice }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Papa from 'papaparse';
import ExcelJS from 'exceljs/dist/exceljs.min.js';

export default {
  name: 'ResultTable',
  components: {},
  props: {
    csvList: Array,
    csv: Boolean,
  },
  data: () => ({
    search: '',
  }),
  computed: {
    headers() {
      return [
        {title: this.$t('resultTable.headers.index'), key: 'idx'},
        {title: this.$t('resultTable.headers.descriptions'), key: 'itemDesc'},
        {title: this.$t('resultTable.headers.taricCode'), key: 'cCode'},
        {title: this.$t('resultTable.headers.gross'), key: 'gross'},
        {title: this.$t('resultTable.headers.net'), key: 'net'},
        {title: this.$t('resultTable.headers.eur1'), key: 'eur1'},
        {title: this.$t('resultTable.headers.amount'), key: 'totalAmount'},
        {title: this.$t('resultTable.headers.boxes'), key: 'boxes'},
        {title: this.$t('resultTable.headers.genOrigin'), key: 'genOrigin'},
        {title: this.$t('resultTable.headers.origin'), key: 'origin'},
        {title: this.$t('resultTable.headers.invoice'), key: 'invoice'}
      ];
    },
    getCsvList() {
      return this.csvList;
    }
  },
  watch: {
    csv(val) {
      if (val) {
        this.exportToExcel();
        this.$emit('close-print-csv')
      }
    }
  },
  methods: {
    getEur1(val) {
      return val ? 'Yes' : 'No';
    },
    async exportToExcel() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Sheet 1');

      // Заглавието на таблицата
      worksheet.addRow(this.headers.map(header => header.title));

      // Данните за таблицата
      this.csvList.forEach((rowData, index) => {
        const row = [];
        this.headers.forEach(header => {
          if (header.key === 'idx') {
            row.push(index + 1);
          } else {
            row.push(rowData[header.key]);
          }
        });
        worksheet.addRow(row);
      });

      // Генерирайте и изтеглете Excel файл
      const blob = await workbook.xlsx.writeBuffer();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'exported_data.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    },
    exportCSV() {
      // Преобразувайте данните в CSV формат с помощта на papaparse
      // const csv = Papa.unparse(this.csvList);

      const csv = Papa.unparse(this.csvList, {
        encoding: 'ISO-8859-1'
      });
      // Създайте Blob обект със съдържанието на CSV
      const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});

      // Генерирайте URL към Blob обекта
      const url = window.URL.createObjectURL(blob);

      // Създайте "а" елемент за сваляне на файла и настройте неговите атрибути
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'exported_data.csv';

      // Добавете "а" елемента към DOM и щракнете върху него, за да започнете свалянето
      document.body.appendChild(a);
      a.click();

      // Премахнете "а" елемента и освободете URL след свалянето
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    },
  },
}

</script>


<style scoped>

</style>
