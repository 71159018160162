<template>
  <div>
    <div class="text-right mt-8">
      <v-btn
        class="text-none"
        color="teal-darken-1"
        :text="$t('fullVersion.toBaseModule')"
        variant="plain"
        @click="toBaseModule"
      />
    </div>
    <div class="base-style text-center">
      <v-label class="bold-label csv-custom-label">{{ $t('example.title') }}</v-label>
    </div>
    <div>
    </div>
    <v-container>
      <div class="helper-header mb-5">
<!--        <p class="text-left">{{$t('example.ruleColumnName')}}</p>-->
<!--        <p class="text-left">{{$t('example.helpCSVcoma')}}</p>-->
        <p class="text-left">{{$t('example.helpOrderColumn')}}</p>
        <p class="text-left">{{$t('example.helpLowerUpperCase')}}</p>
      </div>
<!--      <div v-if="currentUser" class="text-center">-->
        <v-card class="r-card">
          <v-data-table
            class="pa-5"
            :density="'compact'"
            :headers="headers"
            :items="exampleList">
            <template v-slot:headers="{ columns }">
              <tr class="v-data-table__header">
                <template v-for="column in columns" :key="column.key">
                  <th class="e-table-header">
                    {{ column.title }}
                  </th>
                </template>
              </tr>
            </template>
            <template v-slot:item="{ item, index }">
              <tr>
                <td class="text-left">{{ item.itemDesc }}</td>
                <td>{{ item.cCode }}</td>
                <td>{{ item.gross }}</td>
                <td>{{ item.net }}</td>
                <td>{{ item.eur1 }}</td>
                <td>{{ item.psc }}</td>
                <td>{{ item.totalAmount }}</td>
                <td>{{ item.origin }}</td>
                <td>{{ item.boxes }}</td>
                <td>{{ item.invoice }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
<!--      </div>-->
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'Example',
  components: {},
  data: () => ({
    exampleList: [
      {
        itemDesc: 'Дървени палети',
        cCode: '4415202000',
        gross: '380',
        net: '375',
        eur1: 'no eur1',
        totalAmount: '190',
        boxes: '100',
        origin: 'EU',
        psc: 19,
        invoice: '12345/01.01.2024',
      },
      {
        itemDesc: 'Бял Шоколад',
        cCode: '1704903000',
        gross: '354.500',
        net: '320',
        eur1: '',
        totalAmount: '3934.00',
        boxes: '128',
        origin: 'EU',
        psc: 1940,
        invoice: '54321/01.01.2024',
      }
    ]
  }),
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    headers() {
      return [
        {title: 'description, items, desc, goods, описание, стоки', key: 'itemDesc'},
        {title: 'tarik code, tariff code, customs code, тарифен номер, тарифен код', key: 'cCode'},
        {title: 'gross weight, total kg, gross, bruto, бруто', key: 'gross'},
        {title: 'net weight total kg, net, nett, neto, netto, нето', key: 'net'},
        {title: 'eur1', key: 'eur1'},
        {title: 'amount, total amount, price, value, тотал цена', key: 'totalAmount'},
        {title: 'quantity boxes, coll, koll, колети, брои колети, брой колети', key: 'boxes'},
        {title: 'origin, произход', key: 'origin'},
        {title: 'quantity psc, quantities, pieces, Order Quantity, бройка, бройки', key: 'psc'},
        {title: 'invoice, inv, invoices,  фактура, фактури', key: 'invoice'}
      ];
    },
  },
  methods: {
    toBaseModule() {
      this.$emit('open-base-module');
    }
  }
}
</script>


<style scoped>
.v-data-table__header {
  vertical-align: top;
  font-family: 'Roboto', serif;
  font-weight: bolder;
}

.e-table-header {
  font-weight: bolder;
  font-size: 18px;
  font-family: 'Roboto', serif;
}

.helper-header {
  font-family: 'Roboto', serif;
  color: #3cada3 !important;

}

.r-card {
  border-radius: 10px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);
}
</style>
