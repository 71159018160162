<template>
  <div v-if="getCsvList !== null">
    <div class="base-style text-center">
      <v-label class="bold-label csv-custom-label">{{$t('resultViewCsv.headerTitle')}}</v-label>
    </div>
    <v-container
      :fluid="true"
      class="user-card-container ma-3"
    >
      <v-card
        elevation="5"
        class="user-card"
        v-for="(csv,index) in getCsvList"
        :key="csv.id"
      >
        <h3 class="ml-3">Goods № {{ index + 1 }}</h3>
        <div class="d-inline-flex flex-column ma-3 goods-text ">
          <span class="d-inline-flex"><h4 style="margin-right: 10px;">{{$t('resultViewCsv.taricCode')}}</h4> {{ csv.cCode }}</span>
          <span class="d-inline-flex"><h4 style="left: auto; margin-right: 10px;">{{$t('resultViewCsv.descriptions')}}</h4> {{ csv.itemDesc }}</span>
          <span class="d-inline-flex"><h4 style="margin-right: 10px;">{{$t('resultViewCsv.boxes')}}</h4> {{ csv.boxes }}</span>
          <span class="d-inline-flex"><h4 style="margin-right: 10px;">{{$t('resultViewCsv.genOrigin')}}</h4> {{ csv.genOrigin }}</span>
          <span class="d-inline-flex"><h4 style="margin-right: 10px;">{{$t('resultViewCsv.origin')}}</h4> {{ csv.origin }}</span>
          <span class="d-inline-flex"><h4 style="margin-right: 10px;">{{$t('resultViewCsv.gross')}}</h4> {{ csv.gross }}</span>
          <span class="d-inline-flex"><h4 style="margin-right: 10px;">{{$t('resultViewCsv.net')}}</h4> {{ csv.net }}</span>
          <span class="d-inline-flex"><h4 style="margin-right: 10px;">{{$t('resultViewCsv.amount')}}</h4> {{ csv.totalAmount }}</span>
          <span class="d-inline-flex"><h4 style="margin-right: 10px;">{{$t('resultViewCsv.eur1')}}</h4> {{ getEur1(csv.eur1) }}</span>
          <span class="d-inline-flex"><h4 style="margin-right: 10px;">{{$t('resultViewCsv.invoice')}}</h4> {{csv.invoice }}</span>
        </div>

      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'ResultViewCsv',
  components: {},
  props: {
    csvList: {
      type: Array,
      default: null
    },
  },
  data: () => ({}),
  computed: {
    getCsvList() {
      return this.csvList;
    }
  },
  methods: {
    getEur1(val) {
      return val ? 'Yes' : 'No';
    }
  },
}

</script>

<style scoped>
.user-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.user-card {
  background-color: #e0e0e0;
  flex: 0 0 calc(25% - 20px);
  max-width: calc(25% - 20px);
  margin: 10px;
}

@media (max-width: 600px) {
  .user-card {
    flex-basis: calc(100% - 20px);
    max-width: calc(100% - 20px);
  }
}

</style>
