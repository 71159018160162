<template>
  <div>
  </div>
</template>

<script>

const xmlbuilder = require('xmlbuilder');

export default {
  name: 'ExportXml',
  components: {},
  props: {
    csvList: Array,
    exportXml: Boolean,
    mode: String,
    chosenCurrency: String,
    chosenRate: Number,
  },
  data: () => ({
    error: null,
    totalAmount: null,
    totalGross: null,
    totalPackages: null,
    emptyText: ' ',
  }),
  watch: {
    exportXml(val) {
      if (val) {
        this.convertXMLNew();
      }
    }
  },
  methods: {
    convertXMLNew() {
      if (this.mode === 'import') {
        this.modelImportXml();
      } else if (this.mode === 'export') {
        this.modelExportXml();
      }
    },
    modelImportXml() {
      const data = this.getImportJsonCsv();
      const xmlData = data.map((item, index) => {
        const xmlItem = this.createXmlFromObject({GOODITEM: item});
        return `${xmlItem}`;
      });

      const finalXml = `<Root>${xmlData.join('')}</Root>`;
      const xmlString = this.reworkImportXML(finalXml, data.length);

      const blob = new Blob([xmlString], {type: 'application/xml'});

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      const currentDate = new Date();
      a.download = `H1_BG005100_${currentDate.getTime()}.xml`;
      a.click();

      window.URL.revokeObjectURL(url);
      this.$emit('reset-file')
    },

    reworkImportXML(mainXmlString, sizeOfItems) {
      const startIndex = mainXmlString.indexOf('<GOODITEM>');
      const endIndex = mainXmlString.indexOf('</Root>');
      const newXml = mainXmlString.substring(startIndex, endIndex);
      const preparationTS = this.fetchPreparationTS();

      return `<?xml version="1.0" encoding="UTF-8"?>
<BG415A>
 <Sender>TRA.APP</Sender>
 <SenderCode></SenderCode>
 <Recipient>MISV.BG</Recipient>
 <RecipientCode>${this.emptyText}</RecipientCode>
 <preparationDateAndTime>${preparationTS}</preparationDateAndTime>
 <IntConRef>${this.emptyText}</IntConRef>
 <MessageId>${this.emptyText}</MessageId>
 <MessageType>BG415A</MessageType>
 <DECHEA>
  <DeclarationCode>H1</DeclarationCode>
  <Lrn>${this.emptyText}</Lrn>
  <DeclarationType>IM</DeclarationType>
  <AddDeclarationType>A</AddDeclarationType>
  <TotalAmountInvoiced>${this.totalAmount.toFixed(2)}</TotalAmountInvoiced>
  <InvoiceCurrency>${this.chosenCurrency}</InvoiceCurrency>
  <TotalGrossMassKg>${this.totalGross.toFixed(3)}</TotalGrossMassKg>
  <TotalNumberOfItems>${sizeOfItems}</TotalNumberOfItems>
  <TotalPackages>${this.totalPackages}</TotalPackages>
 </DECHEA>
 <REPRESENTATIVE>
  <TIN>${this.emptyText}</TIN>
  <StatusCode>2</StatusCode>
 </REPRESENTATIVE>
 <BORTRANSMEANS>
  <NatOfMeansOfTransCrosBorder>${this.emptyText}</NatOfMeansOfTransCrosBorder>
  <ModeOfTransAtBorder>1</ModeOfTransAtBorder>
 </BORTRANSMEANS>
 <DECLARANT>
  <TIN>${this.emptyText}</TIN>
 </DECLARANT>
 <EXPORTER>
  <Name>${this.emptyText}</Name>
  <ADDRESS>
   <City>${this.emptyText}</City>
   <Country>${this.emptyText}</Country>
   <StreetAndNumber>${this.emptyText}</StreetAndNumber>
   <Postcode>${this.emptyText}</Postcode>
  </ADDRESS>
 </EXPORTER>
 <GOODSSHIPMENT>
  <NatureOfTransaction>11</NatureOfTransaction>
  <CONSIGNMENT>
   <ContainerInd>0</ContainerInd>
   <ARRIVALTRANSPORTMEANS>
    <IdeOfMeaOfTraAtArrival>${this.emptyText}</IdeOfMeaOfTraAtArrival>
    <IdeOfMeaOfTraAtArrivalCode>30</IdeOfMeaOfTraAtArrivalCode>
   </ARRIVALTRANSPORTMEANS>
   <LocationOfGoods>
    <typeOfLocation>D</typeOfLocation>
    <qualifierOfIdentification>Z</qualifierOfIdentification>
    <ADDRESS>
     <City>${this.emptyText}</City>
     <Country>BG</Country>
     <StreetAndNumber>СОФИЯ</StreetAndNumber>
     <Postcode>1000</Postcode>
    </ADDRESS>
   </LocationOfGoods>
  </CONSIGNMENT>
  <DESTINATION>
   <DestinationCountryCode>BG</DestinationCountryCode>
  </DESTINATION>
  <EXPORTCOUNTRY>
   <CountryOfDispatch>${this.emptyText}</CountryOfDispatch>
  </EXPORTCOUNTRY>
${newXml}
<IMPORTER>
   <TIN>${this.emptyText}</TIN>
  </IMPORTER>
  <DeliveryTerms>
   <incotermCode>DAP</incotermCode>
   <country>BG</country>
   <location>СОФИЯ</location>
  </DeliveryTerms>
  <PreviousDocument>
   <referenceNumber>${this.emptyText}</referenceNumber>
   <type>${this.emptyText}</type>
  </PreviousDocument>
 </GOODSSHIPMENT>
 <LODGINGOFFICE>
  <CustOfficeCode>BG005807</CustOfficeCode>
 </LODGINGOFFICE>
</BG415A>`;
    },
    modelExportXml() {
      const data = this.getExportJsonCsv();
      const xmlData = data.map((item, index) => {
        const xmlItem = this.createXmlFromObject({GoodsItem: item});
        return `${xmlItem}`;
      });

      const finalXml = `<Root>${xmlData.join('')}</Root>`;
      const xmlString = this.reworkExportXML(finalXml, data.length);

      const blob = new Blob([xmlString], {type: 'application/xml'});

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      const currentDate = new Date();
      a.download = `B_BG005100_${currentDate.getTime()}.xml`;
      a.click();

      window.URL.revokeObjectURL(url);
      this.$emit('reset-file')
    },

    fetchPreparationTS() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      const day = currentDate.getDate().toString().padStart(2, '0');
      const hours = currentDate.getHours().toString().padStart(2, '0');
      const minutes = currentDate.getMinutes().toString().padStart(2, '0');
      const seconds = currentDate.getSeconds().toString().padStart(2, '0');

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    },

    reworkExportXML(finalXml, sizeOfItems) {
      const startIndex = finalXml.indexOf('<GoodsItem>');
      const endIndex = finalXml.indexOf('</Root>');
      const newXml = finalXml.substring(startIndex, endIndex);
      const preparationTS = this.fetchPreparationTS();

      return `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<ie:BG515C xmlns:ie="http://ecs.dgtaxud.ec">
  <messageSender>TRA.APP</messageSender>
  <messageSenderCode></messageSenderCode>
  <messageRecipient>NECA.BG</messageRecipient>
  <messageRecipientCode>BG005100</messageRecipientCode>
  <preparationDateAndTime>${preparationTS}</preparationDateAndTime>
  <messageIdentification>1</messageIdentification>
  <messageType>BG515C</messageType>
  <correlationIdentifier></correlationIdentifier>
  <ExportOperation>
    <LRN></LRN>
    <declarationType>EX</declarationType>
    <additionalDeclarationType>A</additionalDeclarationType>
    <presentationOfTheGoodsDateAndTime>${preparationTS}</presentationOfTheGoodsDateAndTime>
    <security>2</security>
    <totalAmountInvoiced>${this.totalAmount.toFixed(2)}</totalAmountInvoiced>
    <invoiceCurrency>EUR</invoiceCurrency>
  </ExportOperation>
  <CustomsOfficeOfExport>
    <referenceNumber>BG005100</referenceNumber>
  </CustomsOfficeOfExport>
  <CustomsOfficeOfExitDeclared>
    <referenceNumber>BG005804</referenceNumber>
  </CustomsOfficeOfExitDeclared>
  <Exporter>
    <identificationNumber></identificationNumber>
  </Exporter>
  <Declarant>
    <identificationNumber></identificationNumber>
  </Declarant>
  <Representative>
    <identificationNumber></identificationNumber>
    <status>2</status>
  </Representative>
  <CurrencyExchange>
  <internalCurrencyUnit>BGN</internalCurrencyUnit>
    <exchangeRate>${this.chosenRate}</exchangeRate>
  </CurrencyExchange>
  <GoodsShipment>
    <natureOfTransaction>11</natureOfTransaction>
    <countryOfExport>BG</countryOfExport>
    <countryOfDestination></countryOfDestination>
    <DeliveryTerms>
      <incotermCode></incotermCode>
      <location></location>
      <country></country>
    </DeliveryTerms>
    <Consignment>
      <containerIndicator></containerIndicator>
      <inlandModeOfTransport></inlandModeOfTransport>
      <modeOfTransportAtTheBorder></modeOfTransportAtTheBorder>
      <grossMass>${this.totalGross.toFixed(3)}</grossMass>
      <Consignee>
        <name></name>
        <Address>
          <streetAndNumber></streetAndNumber>
          <postcode></postcode>
          <city></city>
          <country></country>
        </Address>
      </Consignee>
      <LocationOfGoods>
        <typeOfLocation>D</typeOfLocation>
        <qualifierOfIdentification>Z</qualifierOfIdentification>
        <Address>
          <streetAndNumber></streetAndNumber>
          <postcode></postcode>
          <city></city>
          <country></country>
        </Address>
      </LocationOfGoods>
      <DepartureTransportMeans>
        <sequenceNumber>1</sequenceNumber>
        <typeOfIdentification>30</typeOfIdentification>
        <identificationNumber></identificationNumber>
        <nationality>BG</nationality>
      </DepartureTransportMeans>
      <ActiveBorderTransportMeans>
        <typeOfIdentification></typeOfIdentification>
        <identificationNumber></identificationNumber>
        <nationality></nationality>
      </ActiveBorderTransportMeans>
      <TransportCharges>
        <methodOfPayment>D</methodOfPayment>
      </TransportCharges>
    </Consignment>
    ${newXml}
    </GoodsShipment>
</ie:BG515C>
    `
    },
    createXmlFromObject(obj) {
      let xml = '';

      for (const key in obj) {
        if (typeof obj[key] === 'object') {
          xml += `<${key}>${this.createXmlFromObject(obj[key])}</${key}>`;
        } else {
          xml += `<${key}>${obj[key]}</${key}>`;
        }
      }

      return xml;
    },
    getImportJsonCsv() {
      this.totalAmount = null;
      this.totalGross = null;
      this.totalPackages = null;
      return this.csvList.map((obj, idx) => {
        this.totalAmount += obj.totalAmount;
        this.totalGross += obj.gross;
        this.totalPackages += obj.boxes;
        return {
          GoodItemNo: idx + 1,
          StatisticalValue: (obj.totalAmount * this.chosenRate).toFixed(3),
          Commodity: {
            descriptionOfGoods: obj.itemDesc,
            CommodityCode: {
              harmonizedSystemSubheadingCode: obj.cCode.substring(0, 6),
              combinedNomenclatureCode: obj.cCode.substring(6, 8),
              taricCode: obj.cCode.substring(8, obj.cCode.length),
              ADDTARICCODE: {
                TaricAddCode: '0000'
              },
              ADDCOMMODITYCODE: {
                NationalCode: '0000'
              }
            },
            DUTYTAXFEE: {
              Preference: '100'
            },
            GOODSMEASURE: {
              GrossMassKg: obj.gross.toString(),
              NetMassKg: obj.net.toString(),
            },
            INVOICELINE: {
              ItemPrice: obj.totalAmount.toString(),
              InvDest: 2
            }
          },
          CUSTOMSVALUATION: {
            ValuationMethod: 1,
            // 'AdditionsAndDeductions': [
            //  {
            //    'code': 'BA',
            //    'amount': 0
            //  },
            //  {
            //    'code': 'FF',
            //    'amount': 0
            //  }
            // ]
          },
          Procedure: {
            requestedProcedure: '40',
            previousProcedure: '00',
            AdditionalProcedure: {
              additionalProcedure: '000'
            }
          },
          ORIGIN: {
            CountryOfOrigin: obj.genOrigin,
            // CountryOfPrefOrigin: 'TR'
          },
          PACKAGING: {
            ShippingMarks: 'КОЛЕТА',
            NumberOfPackages: obj.boxes.toString(),
            TypeOfPackages: 'PC'
          },
          // SupportingDocument: [
          //   {
          //     referenceNumber: 'IHR2023000000015/20.10.2023',
          //     type: 'N380'
          //   },
          //   {
          //     referenceNumber: 'A5438527',
          //     type: 'N851'
          //   },
          //   {
          //     referenceNumber: 'L0150664',
          //     type: 'N954'
          //   }
          // ],
          // TransportDocument: [
          //   {
          //     type: 'N271',
          //     referenceNumber: 'IHR2023000000015/20.10.2023'
          //   },
          //   {
          //     type: 'N730',
          //     referenceNumber: '1БР.'
          //   }
          // ],
          // AdditionalReference: {
          //   type: 'Y929',
          //   referenceNumber: 'НЕ ПОПАДА'
          // }
        }
      })
    },
    getExportJsonCsv() {
      this.totalAmount = null;
      this.totalGross = null;
      this.totalPackages = null;
      return this.csvList.map((item, index) => {
        this.totalAmount += item.totalAmount;
        this.totalGross += item.gross;
        this.totalPackages += item.boxes;
        return {
          // GoodsItem: {
          declarationGoodsItemNumber: index + 1,
          statisticalValue: (item.totalAmount * this.chosenRate).toFixed(2),
          Procedure: {
            requestedProcedure: '10',
            previousProcedure: '00'
          },
          Origin: {
            countryOfOrigin: item.origin,
            // regionOfDispatch: item.origin
          },
          Commodity: {
            descriptionOfGoods: item.itemDesc.trim(),
            CommodityCode: {
              harmonizedSystemSubHeadingCode: item.cCode.substring(0, 6),
              combinedNomenclatureCode: item.cCode.substring(6)
            },
            GoodsMeasure: {
              grossMass: item.gross,
              netMass: item.net
            }
          },
          Packaging: {
            sequenceNumber: '1',
            typeOfPackages: 'PC',
            numberOfPackages: item.boxes.toString(),
            shippingMarks: 'КОЛЕТА'
          }
          // }
        };
      });
    }
  }
}
</script>


<style scoped>

</style>
