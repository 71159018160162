<template>
  <div v-if="currentUser"
       class="base-style">
    <v-container>
      <v-form ref="addData">
        <div class="d-flex mt-8">
          <v-switch
            class="mx-8"
            color="green-lighten-2"
            density="compact"
            :label="$t('fullVersion.fullEditing')"
            v-model="fullEditing"
          />

          <v-radio-group
            class="ml-16"
            v-model="fullVerMode"
            :inline="true"
          >
            <v-radio
              value="import"
              :class="isImportActive ? 'import-rLabel-active' : 'colored-rLabel'"
            >
              <template v-slot:label>
                <v-label class="label-rBtn">{{ $t('readCsv.importLabel') }}</v-label>
              </template>
            </v-radio>
            <v-radio
              value="export"
              :class="isExportActive ? 'export-rLabel-active' : 'colored-rLabel'"
            >
              <template v-slot:label>
                <v-label class="label-rBtn">{{ $t('readCsv.exportLabel') }}</v-label>
              </template>
            </v-radio>
          </v-radio-group>


          <div class="flex-grow-1 text-right ">
            <v-btn
              class="text-none"
              color="teal-darken-1"
              :text="$t('fullVersion.toBaseModule')"
              variant="plain"
              @click="toBaseModule"
            />
          </div>
        </div>

        <v-card class="rounded-card">
          <div class="d-flex flex-grow-1 align-center mt-5 mx-5">
            <calendar
              v-if="getFullEditing"
              @as-of-date="chosenDate"/>


            <v-text-field
              variant="underlined"
              class="mx-5 bold-label"
              label="LRN"
              :style="{ 'max-width': '400px' }"
              v-model="getLrn"
            />

            <v-autocomplete
              variant="underlined"
              :class="getInheritanceClass('mx-5')"
              :label="$t('fullVersion.custOfficeCode')"
              :style="{ 'max-width': '200px' }"
              return-object
              item-value="value"
              item-title="text"
              v-model="custOfficeCode.value"
              :items="custOfficeCode.items"
            />

            <v-select
              v-show="getFullEditing"
              class="mx-5"
              variant="underlined"
              :label="$t('fullVersion.declarationType')"
              :style="{ 'max-width': '80px' }"
              v-model="declarationType.value"
              :items="declarationType.items"
            />

            <v-select
              v-show="getFullEditing"
              class="mx-5"
              variant="underlined"
              :label="$t('fullVersion.addDeclarationType')"
              :style="{ 'max-width': '200px' }"
              return-object
              item-value="value"
              item-title="text"
              v-model="addDeclarationType.value"
              :items="addDeclarationType.items"
            />

          </div>

          <v-divider class="mx-8"/>




          <div class="d-flex  align-center mt-5 mx-5">

            <exporter
              :isImportActive="isImportActive"
            />

            <v-card class="mx-5 rounded-card">
              <v-text-field
                :class="getInheritanceClass('mx-5')"
                variant="underlined"
                class="mx-5"
                :label="'Обща фактурна стойност'"
                :style="{ 'max-width': '150px' }"
                v-model="totalAmountInvoiced"
              />

              <v-text-field
                class="ml-2"
                :model-value="getCurrencyValue"
                :readonly="true"
                :label="getChosenCurrency"
                variant="underlined"
              />
              <v-autocomplete
                :class="getInheritanceClass('mx-16')"
                variant="underlined"
                v-model="chosenCurrency"
                item-value="code"
                item-title="codeCurrencyText"
                :multiple="false"
                :label="$t('readCsv.chooseCurrency')"
                :items="getCurrencyList"
              />


            </v-card>

            <div class="d-flex">
              <div class="d-inline">
                <v-autocomplete
                  :class="getInheritanceClass('mx-16')"
                  :style="{ 'max-width': '100px' }"
                  variant="underlined"
                  v-model="chosenCurrency"
                  item-value="code"
                  item-title="code"
                  :multiple="false"
                  :label="$t('readCsv.chooseCurrency')"
                  :items="getCurrencyList"
                />

                <v-text-field
                  class="ml-2"
                  :style="{ 'max-width': '100px' }"
                  :model-value="getCurrencyValue"
                  :readonly="true"
                  :label="getChosenCurrency"
                  variant="underlined"
                />

                <v-text-field
                  :class="getInheritanceClass('mx-5')"
                  variant="underlined"
                  class="mx-5"
                  :label="'Обща фактурна стойност'"
                  :style="{ 'max-width': '180px' }"
                  v-model="totalAmountInvoiced"
                />
              </div>
              <div>
                <v-autocomplete
                  :class="getInheritanceClass('mx-16')"
                  :style="{ 'max-width': '100px' }"
                  variant="underlined"
                  v-model="chosenCurrency"
                  item-value="code"
                  item-title="code"
                  :multiple="false"
                  :chips="true"
                  :label="$t('readCsv.chooseCurrency')"
                  :items="getCurrencyList"
                />

                <v-text-field
                  class="ml-2"
                  :style="{ 'max-width': '100px' }"
                  :model-value="getCurrencyValue"
                  :readonly="true"
                  :label="getChosenCurrency"
                  variant="underlined"
                />

                <v-text-field
                  :class="getInheritanceClass('mx-5')"
                  variant="underlined"
                  class="mx-5"
                  :label="'Обща фактурна стойност'"
                  :style="{ 'max-width': '180px' }"
                  v-model="totalAmountInvoiced"
                />
              </div>
            </div>


          </div>
          <div class="d-flex  align-center mt-5 mx-5">
          <exporter
            :isImportActive="isImportActive"
          />
            <exporter
              :isImportActive="isImportActive"
            />
            <exporter
              :isImportActive="isImportActive"
            />
            <exporter
              :isImportActive="isImportActive"
            />
            <exporter
              :isImportActive="isImportActive"
            />
            <exporter
              :isImportActive="isImportActive"
            />
            <exporter
              :isImportActive="isImportActive"
            />
            <exporter
              :isImportActive="isImportActive"
            />
            <exporter
              :isImportActive="isImportActive"
            />
            <exporter
              :isImportActive="isImportActive"
            />
            <exporter
              :isImportActive="isImportActive"
            />
          </div>
          <v-container>
            <company/>
          </v-container>
        </v-card>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import Company from '@/components/fullVersion/Company.vue';
import Calendar from '@/components/calendar/Calendar.vue';
import {fullVer} from '@/mixins/fullVer.js';
import helpDataService from '@/services/help-data-service';
import Exporter from '@/components/fullVersion/Exporter.vue';

export default {
  name: 'FullVersion',
  mixins: [fullVer],
  components: {Exporter, Calendar, Company},
  props: {
    mode: String,
    currencyList: Array,
    eurCountryList: Array,
  },
  data() {
    return {
      fullEditing: true,
      fullVerMode: 'import',
      additionalData: {
        sender: 'Exporter',
        importer: 'Importer',
        fullVerMode: 'import',
      },
      asOfDate: null,
      lrn: '',
      custOfficeCode: {
        value: '',
        items: helpDataService.bgCustomsOffices(),
      },
      declarationType: {
        value: 'IM',
        items: ['CO', 'IM'],
      },
      addDeclarationType: {
        value: 'A - за стандартна митническа декларация (по член 162 от Кодекса)',
        items: helpDataService.declarationType(),
      },
      totalAmountInvoiced: null,

    }
  },
  mounted() {

  },
  computed: {
    getFullEditing() {
      return this.fullEditing;
    },
    getLrn() {
      return this.generateLrn();
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    isImportActive() {
      return this.fullVerMode === 'import';
    },
    isExportActive() {
      return this.fullVerMode === 'export';
    },
  },
  watch: {
    mode: {
      handler: function (val) {
        if (val) {
          this.fullVerMode = val;
        }
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    generateLrn() {
      const year = String(new Date().getFullYear()).slice(-2);
      const randomFourDigitNumber = Math.floor(1000 + Math.random() * 9000);
      const randomFiveDigitNumber = Math.floor(10000 + Math.random() * 90000);
      const numLrn = this.isImportActive ? 'H' : 'B';
      this.lrn = `${year}000000000${randomFourDigitNumber}${numLrn}00${randomFiveDigitNumber}`
      return this.lrn;
    },
    chosenDate(date) {
      this.asOfDate = date;
    },
    resetAdditional() {
      // this.$refs.addData.reset();
    },
    toBaseModule() {
      this.additionalData.fullVerMode = this.fullVerMode;
      this.$emit('open-base-module', this.additionalData);
    },
    getInheritanceClass(addClass) {
      return this.isImportActive ? `${addClass} import-rLabel-active` : `${addClass} export-rLabel-active`;
    },
  }
}
</script>


<style scoped>

.no-border {
  border-bottom: none !important;
}

.import-rLabel-active {
  //font-weight: 420;
  color: teal !important;
}

.export-rLabel-active {
  //font-weight: 520;
  color: dodgerblue !important;
}

</style>
