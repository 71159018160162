<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  components: {},
  mounted() {
    document.title = '<docu/>';
  },

}
</script>

<style scoped>

</style>
