function bgCustomsOffices() {
  return [
    {value: 'BG001000', text: 'BG001000 - ТД Митница Бургас'},
    {value: 'BG001002', text: 'BG001002 - МП Летище Бургас'},
    {value: 'BG001003', text: 'BG001003 - МП М.Търново'},
    {value: 'BG001005', text: 'BG001005 - МБ Нефтохимически комбинат'},
    {value: 'BG001007', text: 'BG001007 - МП Пристанище Бургас център'},
    {value: 'BG001008', text: 'BG001008 - МБ Свободна Зона'},
    {value: 'BG001009', text: 'BG001009 - МБ Сливен'},
    {value: 'BG001010', text: 'BG001010 - МБ Ямбол'},
    {value: 'BG001011', text: 'BG001011 - МП Лесово'},
    {value: 'BG001012', text: 'BG001012 - МП Царево'},
    {value: 'BG001013', text: 'BG001013 - МП Нефтопристанище'},
    {value: 'BG001014', text: 'BG001014 - МП Ж.П. Гара Свиленград'},
    {value: 'BG001015', text: 'BG001015 - МП Капитан Андреево'},
    {value: 'BG002000', text: 'BG002000 - ТД Митница Варна'},
    {value: 'BG002002', text: 'BG002002 - МП Пристанище Варна-Запад'},
    {value: 'BG002003', text: 'BG002003 - МП Летище Варна'},
    {value: 'BG002005', text: 'BG002005 - МП Пристанище Варна'},
    {value: 'BG002006', text: 'BG002006 - МП Пристанище Леспорт'},
    {value: 'BG002007', text: 'BG002007 - МП Варна-Ферибот'},
    {value: 'BG002008', text: 'BG002008 - МБ Шумен'},
    {value: 'BG002009', text: 'BG002009 - МБ Добрич'},
    {value: 'BG002010', text: 'BG002010 - МП Балчик'},
    {value: 'BG003000', text: 'BG003000 - ТД Митница Пловдив'},
    {value: 'BG003001', text: 'BG003001 - МБ Казанлък'},
    {value: 'BG003002', text: 'BG003002 - МП Летище Пловдив'},
    {value: 'BG003004', text: 'BG003004 - МБ Пазарджик'},
    {value: 'BG003005', text: 'BG003005 - МБ Свободна зона Пловдив'},
    {value: 'BG003006', text: 'BG003006 - МБ Смолян'},
    {value: 'BG003007', text: 'BG003007 - МБ Карлово'},
    {value: 'BG003008', text: 'BG003008 - МБ Стара Загора'},
    {value: 'BG003009', text: 'BG003009 - МБ Пловдив'},
    {value: 'BG003010', text: 'BG003010 - МБ Свиленград'},
    {value: 'BG003011', text: 'BG003011 - МБ Кърджали'},
    {value: 'BG003012', text: 'BG003012 - МБ Хасково'},
    {value: 'BG003013', text: 'BG003013 - МБ Интермодален терминал'},
    {value: 'BG004000', text: 'BG004000 - ТД Митница Русе'},
    {value: 'BG004002', text: 'BG004002 - МБ Разград'},
    {value: 'BG004006', text: 'BG004006 - МП Пристанище'},
    {value: 'BG004011', text: 'BG004011 - МБ Търговище'},
    {value: 'BG004014', text: 'BG004014 - МП силистра'},
    {value: 'BG004200', text: 'BG004200 - ТМУ Лом'},
    {value: 'BG004201', text: 'BG004201 - МБ Враца'},
    {value: 'BG004203', text: 'BG004203 - МП Оряхово'},
    {value: 'BG004205', text: 'BG004205 - МП Пристанище Лом'},
    {value: 'BG004210', text: 'BG004210 - МБ Монтана'},
    {value: 'BG004211', text: 'BG004211 - МБ Видин'},
    {value: 'BG004212', text: 'BG004212 - МП Брегово'},
    {value: 'BG004213', text: 'BG004213 - МП Връшка чука'},
    {value: 'BG004214', text: 'BG004214 - МП Пистанище Видин'},
    {value: 'BG004300', text: 'BG004300 - МП Пристанище Свищов'},
    {value: 'BG004301', text: 'BG004301 - МБ Габрово'},
    {value: 'BG004302', text: 'BG004302 - МБ Горна Оряховица'},
    {value: 'BG004303', text: 'BG004303 - МП Летище Горна Оряховица'},
    {value: 'BG004304', text: 'BG004304 - МБ Ловеч'},
    {value: 'BG004306', text: 'BG004306 - МБ Плевен'},
    {value: 'BG004307', text: 'BG004307 - МП Сомовит'},
    {value: 'BG004309', text: 'BG004309 - МБ Севлиево'},
    {value: 'BG004311', text: 'BG004311 - МБ Троян'},
    {value: 'BG005100', text: 'BG005100 - МБ Летище София'},
    {value: 'BG005106', text: 'BG005106 - МП Летище София-пътници'},
    {value: 'BG005107', text: 'BG005107 - МП Аерогара София - товари'},
    {value: 'BG005700', text: 'BG005700 - МБ Благоевград'},
    {value: 'BG005701', text: 'BG005701 - МБ Гоце Делчев'},
    {value: 'BG005702', text: 'BG005702 - МП Гюешево'},
    {value: 'BG005703', text: 'BG005703 - МП Златарево'},
    {value: 'BG005704', text: 'BG005704 - МБ Кулата'},
    {value: 'BG005705', text: 'BG005705 - МБ Кюстендил'},
    {value: 'BG005706', text: 'BG005706 - МП Логодаж'},
    {value: 'BG005707', text: 'BG005707 - МП Олтоманци'},
    {value: 'BG005800', text: 'BG005800 - ТД Митница София'},
    {value: 'BG005801', text: 'BG005801 - МБ Ботевград'},
    {value: 'BG005803', text: 'BG005803 - МБ Драгоман'},
    {value: 'BG005804', text: 'BG005804 - МП Калотина'},
    {value: 'BG005805', text: 'BG005805 - МБ Перник'},
    {value: 'BG005807', text: 'BG005807 - МБ София-Запад'},
    {value: 'BG005808', text: 'BG005808 - МБ София-Изток'},
    {value: 'BG005809', text: 'BG005809 - МП Стрезимировци'},
    {value: 'BG005810', text: 'BG005810 - МБ БРСЦ'},
    {value: 'BG005811', text: 'BG005811 - МБ Слатина'},
    {value: 'BG009999', text: 'BG009999 - Централно митническо управление'},
  ]
}

function declarationType() {
  return [
    {
      value: 'A',
      text: 'A - за стандартна митническа декларация (по член 162 от Кодекса)'
    },
    {
      value: 'B',
      text: 'B - за опростена митническа декларация, която се подава инцидентно (по член 166, параграф 1 от Кодекса)'
    },
    {
      value: 'C',
      text: 'C - за опростена митническа декларация за редовно използване (по член 166, параграф 2 от Кодекса)'
    },
    {
      value: 'D',
      text: 'D - за подаване на стандартна митническа декларация (като посочената под код А) в съответствие с член 171 от Кодекса'
    },
    {
      value: 'E',
      text: 'E - за подаване на опростена митническа декларация (като посочената под код В) в съответствие с член 171 от Кодекса'
    },
    {
      value: 'F',
      text: 'F - за подаване на опростена митническа декларация (като посочената под код C) в съответствие с член 171 от Кодекса'
    },
  ]
}

function countries() {
  return ['', 'BG', 'GR'
    // {value: 'empty', text: ''},
    // {value: 'BG', text: 'България'},
    // {value: 'GR', text: 'Гърция'},
  ]
}

function companies() {
  const objMap = new Map();
  objMap.set('empty', {
    eori: '',
    name: '',
    address: '',
    city: '',
    postCode: '',
    country: '',
  });
  objMap.set('ВЕРЖИ - 2010 ЕООД', {
    eori: 'BGC201044852ZZZZ9',
    name: 'ВЕРЖИ - 2010 ЕООД',
    address: 'УЛ. ДИМИТЪР ТОШКОВ №30',
    city: 'С.ЛУКОРСКО',
    postCode: '1513',
    country: 'BG',
  },);
  objMap.set('Николай Иванов', {
    eori: 'BGC201044852ZZZZ9',
    name: 'Николай Иванов',
    address: 'Младост 1 бл.111 вх.1',
    city: 'София',
    postCode: '1800',
    country: 'BG',
  },);

  return objMap;


  // return {
  //   'empty':{
  //     eori: '',
  //     name: '',
  //     address: '',
  //     city: '',
  //     postCode: '',
  //     country: '',
  //   },
  //   'ВЕРЖИ - 2010 ЕООД': {
  //     eori: 'BGC201044852ZZZZ9',
  //     name: 'ВЕРЖИ - 2010 ЕООД',
  //     address: 'УЛ. ДИМИТЪР ТОШКОВ №30',
  //     city: 'С.ЛУКОРСКО',
  //     postCode: '1513',
  //     country: 'BG',
  //   },
  //   'Николай Иванов':{
  //     eori: 'BGC201044852ZZZZ9',
  //     name: 'Николай Иванов',
  //     address: 'Младост 1 бл.111 вх.1',
  //     city: 'София',
  //     postCode: '1800',
  //     country: 'BG',
  //   },
  // }
}

export default {
  bgCustomsOffices,
  declarationType,
  countries,
  companies,
}
