export default class User {
  constructor(firstName, lastName, username, password, rePassword, email) {
    this.username = username;
    this.firstName = firstName;
    this.lastName = lastName;
    this.password = password;
    this.rePassword = rePassword;
    this.email = email;
  }
}
